@path-less: "../../../../public/less";

@import "@{path-less}/functions.less";
@import "@{path-less}/variables.less";

html {
	>body {
        background: url(https://cloud.iminence.com/module/user/public/img/login/background.jpg) no-repeat top center fixed !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        -webkit-background-size: cover !important;
        background-size: cover !important;
		main {
			position: relative;
		    >section {
				position: absolute;
				top: 50%;
				width: 100%;
				transform: translateY(-50%);
				>div {
					width: 400px;
					background: #ffffff;
					margin: auto;
					border: 1px solid #ccc;
					>.page-header {
					    padding-bottom: 5px;
					    margin: 0;
					    margin-bottom: 10px;
					    border-bottom: 1px solid #eeeeee;
					    background: #f3f3f3;
						>h1 {
						    margin: 0;
						    color: #727272;
						    padding: 10px 5px 5px 5px;
						    text-align: center;
						    font-size: 36px;
						    font-family: @fontFamilyCondensed;
						    font-weight: @fontWeightL;
						}
					}
					>form {
						margin: 20px;
						.input-group {
							margin-bottom: 2px;
							>.input-group-addon {
								color: #717171;
								border-radius: 0;
							}
							>.form-control {
								border-radius: 0;
								-moz-box-shadow: inset 0px 0px 1px 1px #fff;
								-webkit-box-shadow: inset 0px 0px 1px 1px #fff;
								box-shadow: inset 0px 0px 1px 1px #fff;
								.transition();
								&:focus {
									border-color: #cbcbcb;
									-moz-box-shadow: inset 0px 0px 1px 1px #cbcbcb;
									-webkit-box-shadow: inset 0px 0px 1px 1px #cbcbcb;
									box-shadow: inset 0px 0px 1px 1px #cbcbcb;
								}
							}
						}
						.btn {
							color: #717171;
							background-color: #ededee;
							border-color: #cbcbcb;
							border-radius: 0;
							.transition();
						}
						.alert {
						    border-radius: 0;
						    margin: 0;
						    padding: 12px;
						    float: left;
						}
					}
				}
		    } 
		}
	}
}